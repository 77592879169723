<template>
  <!-- BEGIN: Content-->
  <div class="app-content content">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top profile__avatar">
            <div class="col-12">
              <h2 class="content-header-title mb-0">
                {{ title }}
              </h2>
            </div>
          </div>
        </div>
        <div
          class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none"
        >
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-primary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              @click="$router.push({name: 'researcher.disseminations.create'})"
            ><i data-feather="plus" /></a>
            <!-- <a
              href="#"
              class="btn btn-icon btn-flat-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Professional background settings"
            ><i data-feather="settings" /></a> -->
          </div>
        </div>
      </div>

      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">
            <CompleteTable
              :sorted-field="sortedField"
              :data="items"
              :sort-mapping="sortMapping"
              :title="'disseminations'"
              :total="itemsTotal"
              :fetch-path="'activities/fetch'"
              :filter-path="'activities/filter'"
              :export-path="'activities/export'"
            >
              <template v-slot="{field, showInfoSheet}">
                <td
                  class="text-end"
                >
                  <b-dropdown
                    variant="link"
                    no-caret
                    toggle-class="p-0"
                    right
                  >
                    <template #button-content>
                      <i data-feather="more-vertical" />
                    </template>
                    <div
                      class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block"
                    >
                      <b-dropdown-item @click="$router.push({name: 'researcher.dashboard', params: { id: field.user.id }})">
                        <i
                          class="me-50"
                          data-feather="user"
                        /> View researcher profile
                      </b-dropdown-item>
                      <b-dropdown-item @click="showInfoSheet(field)">
                        <i
                          class="me-50"
                          data-feather="eye"
                        /> View activity
                      </b-dropdown-item>
                      <div class="dropdown-divider" />
                      <b-dropdown-item @click="$router.push({name: 'researcher.disseminations.edit', params: { id: field.id }})">
                        <i
                          class="me-50"
                          data-feather="edit-3"
                        /> Edit
                      </b-dropdown-item>
                      <b-dropdown-item @click="showDeleteModal(field.id)">
                        <i
                          class="me-50"
                          data-feather="trash-2"
                        /> Delete <span class="float-end"><i data-feather="lock" /></span>
                      </b-dropdown-item>
                    </div>
                  </b-dropdown>
                  <a
                    href=""
                    class="btn btn-icon btn-light-secondary d-block d-sm-none"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasMenu"
                    aria-controls="offcanvasBottom"
                  ><i
                    data-feather="more-vertical"
                  /></a>
                </td>
              </template>
              <template #customfilters>
                <div
                  v-if="loggedUser.roles.includes('super-admin')"
                  class="mb-1"
                >
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Researcher</label>
                  <v-select
                    label="name"
                    :options="users"
                    :get-option-key="option => option.id"
                    @search="onSearch({ name: $event }, 'users/fetchFakeUsers')"
                    @input="saveFilter($event, 'researcher')"
                  />
                  <hr>
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Title</label>
                  <input
                    type="text"
                    class="form-control"
                    name="nameseniorcall"
                    @change="saveFilter($event.target.value, 'title')"
                  >
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Type of disseminations</label>
                  <v-select
                    label="name"
                    :options="types"
                    :get-option-key="option => option.id"
                    @search="onSearch({ name: $event }, 'disseminationTypes/filter')"
                    @input="saveFilter($event, 'dissemination_types')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Country</label>
                  <v-select
                    label="name"
                    :options="countries"
                    @input="saveFilter($event.name, 'country')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Begin date</label>
                  <br>
                  <date-picker
                    v-model="filters['begin_date']"
                    format="D MMM Y"
                    value-type="format"
                    class="w-100"
                    @change="saveFilter($event, 'begin_date')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >End date</label>
                  <br>
                  <date-picker
                    v-model="filters['end_date']"
                    format="D MMM Y"
                    class="w-100"
                    value-type="format"
                    @change="saveFilter($event, 'end_date')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for="nameseniorcall"
                    class="form-label"
                  >Status of Authorization</label>
                  <v-select
                    label="name"
                    :options="statuses"
                    :get-option-key="option => option.id"
                    @input="saveFilter($event, 'status')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >TSS</label>
                  <select
                    id=""
                    name=""
                    class="form-select"
                    @input="saveFilter($event.target.value, 'tss')"
                  >
                    <option value="">
                      All
                    </option>
                    <option value="true">
                      Yes
                    </option>
                    <option value="false">
                      No
                    </option>
                  </select>
                </div>
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >Travel</label>
                  <select
                    id=""
                    name=""
                    class="form-select"
                    @input="saveFilter($event.target.value, 'travel')"
                  >
                    <option value="">
                      All
                    </option>
                    <option value="true">
                      Yes
                    </option>
                    <option value="false">
                      No
                    </option>
                  </select>
                </div>
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >Is virtual?</label>
                  <select
                    id=""
                    name=""
                    class="form-select"
                    @input="saveFilter($event.target.value, 'virtual_activity')"
                  >
                    <option value="">
                      All
                    </option>
                    <option value="true">
                      Yes
                    </option>
                    <option value="false">
                      No
                    </option>
                  </select>
                </div>
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >ICREA active</label>
                  <select
                    id=""
                    name=""
                    class="form-select"
                    @input="saveFilter($event.target.value, 'researcher_active')"
                  >
                    <option value="">
                      All
                    </option>
                    <option value="true">
                      Yes
                    </option>
                    <option value="false">
                      No
                    </option>
                  </select>
                </div>
              </template>
              <template #info-sheet-item="{item}">
                <template v-if="item.content">
                  <PreviewActivity
                    :item="item"
                  />
                </template>
              </template>
            </CompleteTable>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import Vue from 'vue'
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import DatePicker from 'vue2-datepicker'
import CompleteTable from '../../../partials/components/CompleteTable.vue'
import PreviewActivity from '../components/PreviewActivity.vue'
import 'vue2-datepicker/index.css'

export default {
  components: {
    CompleteTable,
    BDropdown,
    BDropdownItem,
    vSelect,
    DatePicker,
    PreviewActivity,
  },
  data() {
    return {
      filters: [],
      sortedField: 'Position',
      defaultFields: [
        {
          name: 'Type',
          checked: true,
          order: 1,
        },
        {
          name: 'Researcher',
          checked: true,
          order: 2,
        },
        {
          name: 'Title',
          checked: true,
          order: 3,
        },
        {
          name: 'Begin date',
          checked: true,
          order: 4,
        },
        {
          name: 'End date',
          checked: true,
          order: 5,
        },
        {
          name: 'Virtual',
          checked: true,
          order: 6,
        },
        {
          name: 'Trip dates',
          checked: true,
          order: 7,
        },
        {
          name: 'Country',
          checked: true,
          order: 7,
        },
        {
          name: 'TSS',
          checked: true,
          order: 8,
        },
        {
          name: 'Travels',
          checked: true,
          order: 9,
        },
        {
          name: 'Show in public website',
          checked: true,
          order: 10,
        },
        {
          name: 'Multimedia',
          checked: true,
          order: 11,
        },
      ],
      sortMapping: {
        Type: 'content.dissemination_type.name',
        Researcher: 'user.name',
        Title: 'content.title',
        'Begin date': 'content.date',
        'End date': 'content.end_date',
        'Trip dates': 'trips_dates',
        TSS: 'tss_table',
        Travels: 'travel_table',
        Country: 'country',
        'Show in public website': 'content.published',
        Virtual: 'content.virtual_activity',
        Multimedia: 'multimediaLabel',
      },
      title: 'Disseminations',
    }
  },
  computed: {
    ...mapGetters({
      fields: 'modals/tableSettingsFields',
      items: 'activities/items',
      itemsTotal: 'activities/itemsTotal',
      types: 'disseminationTypes/items',
      statuses: 'status/statuses',
      users: 'users/fakeUsers',
      loggedUser: 'auth/admin',
      filtersTotal: 'filters/filters',
      countries: 'citizenships/items',
    }),
  },
  async mounted() {
    await this.saveFilter(`1 Jan ${new Date().getFullYear()}`, 'begin_date')
    if (this.$route.query.user_id) {
      await this.saveFilter({ id: this.$route.query.user_id }, 'researcher')
    }
    await this.$store.dispatch('modals/fetchUserFields', 'disseminations')
    await this.$store.dispatch('activities/setType', 'disseminations')
    await this.$store.dispatch('activities/filter', this.filtersTotal)
    await this.$store.dispatch('citizenships/filter', this.$store.getters['filters/filters'])
    await this.$store.dispatch('status/filterStatus', 'Activities')

    if (!this.fields || this.fields.length === undefined) { // If user doesnt have default fields, load the true default
      await this.$store.dispatch('modals/fetchFields', {
        fields: this.defaultFields,
        table: 'disseminations',
      })
    }
    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    saveFilter(value, field) {
      // this.filters[field] = value
      this.$store.dispatch('filters/saveFilters', {
        value,
        field,
      })
    },
    async onSearch(search, store) {
      search.active = true
      if (search.name !== '') {
        await this.$store.dispatch(store, search)
      }
    },
    async showDeleteModal(id) {
      await this.$store.dispatch('modals/saveDeleteData', { name: 'activities/fetch', url: `${Vue.prototype.$groupUrl}/activities/disseminations/activity/${id}`, requiredType: 'disseminations' })
      this.$store.dispatch('modals/showDeleteModal', true)
    },
  },
}
</script>
